<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Digitalization in the African retail pharmaceutical sector (With
        Shelflife by Field intelligence and mPharma)
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="../../assets/blog new/190624-culturally-competent-care-3-khn-ew-349p.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Digitalization in the African retail pharmaceutical sector (With
            Shelflife by Field intelligence and mPharma)
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: Sept 12, 2021</p>
            </span>
          </div>
          <p>
            Shelf Life Director of Pharmacy Suleman Sule, and mPharma’s Joyce
            Aderinlewo talked about how they are helping thousands of Community
            pharmacies use Data and Technology to optimise operations.
          </p>
          <p class="">
            Several sectors have over the years benefitted from the disruptive
            power of technology and the convenience of easily accessible and
            readily available data.
          </p>
          <!-- <p>
            First world countries like Great Britain, America, Russia, China and
            others in collaboration with health institutions like WHO, USAID
            etc. are at the forefront of ensuring the world draws the curtain on
            the pandemic for good, with millions of vaccines being donated to
            different countries with limited resources to tackle the virus, a
            world void of the threat the virus poses look more likely than ever.
          </p>
          <p>
            However, for countries like Nigeria, the most populated country in
            Africa, securing the vaccines is first base. Distributing the
            vaccines across the 36 states of the federation requires a more
            technical approach, structure, and infrastructure that could support
            the effective distribution of these vaccines to the last mile.
          </p>
          <p>
            With 36 states spread across a landmass of 923,768km² to cover,
            NPHCDA, the arm of the Nigerian Government tasked with the
            responsibility of getting these vaccines to the last mile has her
            work cut out, ensuring the vaccines gets to each state in the best
            condition was paramount, and being able to attest to the potency of
            the vaccines is crucial, both of which Gricd technologies and
            software guarantees.
          </p> -->
          <p>
            The invention of X-ray machines, ultrasound and many other Meditech
            equipment and software laid the foundation for the advancement we
            see in the medical world of today. However, the adoption rate of
            medical tech solutions in developing countries is still relatively
            low and this leaves the global medical space with a huge vacuum to
            fill.
          </p>
          <p>
            While new technologies and software are being rolled out into the
            medical world every other day, adoption is not as speedy as one
            would expect, especially for medical facilities in developing
            countries. The community pharmacy space is one of the different
            medical parastatals bearing the brunt of this reality and the effect
            on global health are loudly represented in alarming statistics.
          </p>

          <p>
            Originally community pharmacies are tasked with the responsibility
            of selling prescriptions to the public and advising the public on
            the consumption of pharmaceuticals in general, but in Africa, they
            are responsible for a lot more.
          </p>
          <p class="grey-text font-italic">
            “I think think the biggest constraint to pharmacies performing as
            optimally as possible is their over-reliance on paper-based tools
            and gut feeling” - Suleman Sule, Shelf Life Director of Pharmacy
          </p>
          <p>
            WHO's advised ratio of pharmacy to people stands at 1-10000, while
            some developed countries are doing 1-5000, the ratio for some
            African countries like Nigeria is 1-37000, this is an overwhelming
            number for the community pharmacies in this region and compounded
            with their extended responsibility of almost doubling as health care
            centres, in ensuring good service delivery, digitalization is
            essential.
          </p>
          <p class="grey-text font-italic">
            "What happens currently is that when a person doesn't feel well, the
            first point of call they go to is the pharmacy, if the pharmacy is
            not equipped to keep a proper record of the person's drug history,
            patients will keep coming back to buy different brands of the same
            medication." - Pharm Joyce Aderinlewo.
          </p>
          <p class="grey-text font-italic">
            “I react to sulphur, I have taken fansidar before, if the pharmacy
            has a proper EMR software and they log that when Mrs XYZ comes she
            has black lips when she takes septrin, anytime there's a drug that
            has sulphur it wouldn't be recommended to her” - Pharm Joyce
            Aderinlewo
          </p>

          <figure class="pt-8 pb-4">
            <img
              src="../../assets/blog new/uzLQzA9_6lqMhXrHWxDO6sHzkPzQUzviArS2rGwx6ChC0GgXMlznTq3_MnTTPwo6W-SmN1v3xSpYHg03iSMHd0VMbB2h1MM6H_wuUIA5CRZ4GlLEmjWetc5QtaKzZWpoHE7PxAS6=s0.jpg"
              alt=""
              width="100%"
              class=""
            />
            <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              Pharm Joyce Aderinlewo, Pharmacist at mPharma
            </figcaption>
          </figure>

          <p>
            Automation, speed, and accuracy are three notable advantages of
            digitalisation an overwhelmed sector like the Africa community
            pharmaceutical space would really benefit from, existing in the
            African medical space means having to cater to a huge population,
            this requires speed and maintaining quality service delivery
            requires a level of accuracy. Adoption of Meditech solutions like
            EMR softwares makes operation more seamless and bearable, it affords
            community pharmacies and pharmacists, enhanced data collection, easy
            access to patient records, rids their operation of guesswork, and
            uninformed decisions, and the quality of service could easily be
            vouched for.
          </p>

          <p class="grey-text font-italic">
            “It is important to know that outside the scope of pharmaceutical
            services, pharmacists also have to function as business managers,
            concerning themselves with the procurement of medicine, making sure
            financing is in place, making sure quality assurance, regulatory,
            compliance are in place” - Suleman Sule, Shelf Life Director of
            Pharmacy
          </p>
          <figure class="pt-6">
            <img
              src="../../assets/blog new/WhatsApp Image 2021-09-14 at 12.00.20 PM.jpeg"
              alt=""
              width="100%"
              class=""
            />
            <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              Suleman Sule, Shelf Life Director of Pharmacy
            </figcaption>
          </figure>
          <p>
            For the community pharmacies, the fact that just about 3percent of
            global drug production is done in Africa is not much of a thing to
            be excited about, this means high landing cost of pharmaceuticals
            and essentially makes the selling price higher than it would have
            been if the drugs were produced locally. With the dwindling ability
            of the public to afford medications and healthcare in general,
            compromises are almost inevitable, sadly sometimes this compromise
            doesn’t serve the interest of the public as much.
          </p>
          <p>
            Activities of services like Shelf Life are directly geared towards
            mitigating the risk of unacceptable compromises from community
            pharmacies, ensuring they have quality products by sourcing from the
            manufacturers and providing the product without the need for upfront
            payment.
          </p>
          <p class="grey-text font-italic">
            “We help pharmacies manage their inventories and their operations,
            mPharma’s goal is to ensure all Africans have access to affordable
            quality medicines. We know that the first point of call for every
            African is usually the pharmacy, so we are trying to equip
            pharmacies to ensure that they have the right kind of products and
            the right quality of service, so we do trainings for these
            pharmacies, we provide inventory for them and we also provide
            refurbishment, a facelift for this facilities...” Pharm. Joyce
            Aderinlewo, mPharma.
          </p>
          <figure class="pt-6">
            <img
              src="../../assets/blog new/medicine-bottles-pills.jpg"
              alt=""
              width="100%"
              class=""
            />
            <!-- <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              Suleman Sule, Shelf Life Director of Pharmacy
            </figcaption> -->
          </figure>
          <p>
            Diverse news and reports show that expired drugs worth millions are
            destroyed by different government bodies like Nafdac yearly, experts
            even estimate that the Nigeria pharmacy sector might be losing
            between 20-25percent of its projected revenue to expired drugs.
            Journalists have also reported stories of pharmacies selling expired
            drugs, either out of error or selfish need to make a profit. ERPs
            and services like mPharma and Shelf Life that provide Community
            Pharmacies with products as well as analytics and insights for
            product procurements can essentially mitigate the possibilities of
            expired products.
          </p>
          <figure class="pt-6">
            <img
              src="../../assets/blog new/895f8cf3-what-happens-if-you-consumed-expired-tablets-or-syrups-640x480.jpg"
              alt=""
              width="100%"
              class=""
            />
            <figcaption
              class=""
              style="font-size: 0.8125rem;font-style: italic; color:#999"
            >
              Suleman Sule, Shelf Life Director of Pharmacy
            </figcaption>
          </figure>
          <p>
            In a space such as the African pharmaceutical sector, profitability
            requires precision even when stocking pharmaceutical products.
          </p>

          <p class="grey-text font-italic">
            “What we do on top of providing them with the product is actually
            providing them with the right data needed to drive decision making.
            A particular product will come in many different variants, so many
            different price points, so it’s not just the availability, we are
            able to provide the analytics through the Field Supply or Shelf Life
            platform. With this information we can tell the client that these
            are the products we see to be in demand in certain clusters and then
            they are able to optimise to make a decision. For example, should
            they be stocking a particular product, why is this product moving at
            this particular time, and you begin to see the correlation in trend.
          </p>
          <p class="grey-text font-italic">
            For example, it is currently raining in Nigeria, so the incidence of
            malaria is high, and pharmacies will need some form of data to know
            which of the brands they need to stock. With inflation and the
            varying ability of customers to afford medications you now need to
            figure out what products, and at what price point might be the best
            to stock...” - Suleman Sule, Shelf Life Director Of Pharmacy
          </p>
          <p>
            mPharma and Shelf Life are two of the most disruptive pharmaceutical
            startups in Africa and their activities are helping Africans have
            increased access to quality medication and healthcare.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image5 from "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg";
import image6 from "../../assets/blog new/eyecarengonline_1628779252293258.jpg";
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ["News", "Health", "Logistics"],
    rightSide: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          "INTERVIEW: Managing Director Of Kesona Eye Centre Speaks About Glaucoma And Its Impact On The Health Of Nigerians",
        date: "July 19, 2021",
        link:
          "/blog/interview-managing-director-of-kesona-eye-centre-speaks-about-glaucoma-and-its-impact-on-the-health-of-nigerians",
      },
      {
        img:
          "https://res.cloudinary.com/dcjbgkkej/image/upload/v1631626441/pexels-denis-ngai-4483669_1_i9zs9q.jpg",
        content:
          " Gricd tracks 4.2millions doses of Moderna Vaccines for the Nigerian Government",
        date: "June 13, 2021",
        link:
          "/blog/gricd-tracks-4.2millions-doses-of-moderna-vaccines-for-the-nigerian-government",
      },
      {
        img: image6,
        content:
          " Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
        date: "June 13, 2021",
        link:
          "/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
      },
    ],
    mySVG: require("../../assets/Logo/search.svg"),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: "https://gricd.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: "https://gricd.com/blog",
              },
              {
                "@type": "ListItem",
                position: 3,
                name:
                  "Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians",
                item:
                  "https://gricd.com/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians",
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == "complete") {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener("readystatechange", readyHandler);
      }
    };

    document.addEventListener("readystatechange", readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
</style>
